import { useQuery } from "@tanstack/react-query";
import { Button, Image, Modal, Table, Typography } from "antd";
import { useParams } from "react-router-dom";
import Access from "../../components/Access";
import { getAllPosts } from "../../utils/api";
import { useAuth } from "../../utils/store";

const UserCollaboration = () => {
  const user = useAuth((state) => state.user);
  const id = useParams().id;
  const { data, isLoading } = useQuery({
    queryKey: ["userPosts", id],
    queryFn: () => getAllPosts(id),
    enabled:
      !!user?.viewCompanyCollaboration ||
      !!user?.viewTalentSeekerCollaboration ||
      !!user?.viewTalentCollaboration,
  });
  console.log(data);
  return (
    <Access
      granted={
        user?.viewCompanyCollaboration ||
        user?.viewTalentSeekerCollaboration ||
        user?.viewTalentCollaboration
      }
    >
      <Typography.Title>Collaboration</Typography.Title>
      <Table
        dataSource={data}
        loading={isLoading}
        columns={[
          {
            title: "Image",
            dataIndex: ["user", "image"],
            render: (image) => (
              <Image src={image} alt="user" width={100} height={100} />
            ),
          },
          {
            title: "Name",
            dataIndex: ["user", "name"],
          },
          {
            title: "Phone Number",
            dataIndex: ["user", "phoneNumber"],
          },
          {
            title: "Upload Date",
            dataIndex: ["uploadDate"],
            render: (date) => new Date(date).toLocaleDateString(),
          },
          {
            title: "Player",
            dataIndex: ["link"],
            render: (link, record) =>
              record?.linkType === "Video" ? (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.info({
                      title: "Player",
                      width: 800,
                      content: (
                        <video
                          controls
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <source src={link} type="video/mp4" />
                        </video>
                      ),
                    });
                  }}
                >
                  Watch Video
                </Button>
              ) : (
                <Image src={link} alt="player" width={100} height={100} />
              ),
          },
        ]}
      />
    </Access>
  );
};

export default UserCollaboration;
