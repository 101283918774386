import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Image,
  Modal,
  Popconfirm,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import React from "react";
import Access from "../components/Access";
import {
  approvedCollaboration,
  deleteCollaboration,
  pendingCollaboration,
  rejectedCollaboration,
  updateCollaboration,
} from "../utils/api";
import { useAuth } from "../utils/store";

const Collaboration = ({ type = "Pending" }) => {
  return (
    <div>
      <Typography.Title>Collaboration {type}</Typography.Title>
      <DataTable type={type} />
    </div>
  );
};

export default Collaboration;

const DataTable = ({ type }) => {
  const user = useAuth((state) => state.user);
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery({
    queryFn: async () =>
      type === "Pending"
        ? await pendingCollaboration()
        : type === "Approved"
        ? await approvedCollaboration()
        : await rejectedCollaboration(),
    queryKey: ["collaboration", type],
    enabled: !!user?.viewCollaborations,
  });

  const { mutate: deletePost, isPending: deleting } = useMutation({
    mutationFn: async (values) => await deleteCollaboration(values),
    onSuccess: () => {
      message.success(" deleted successfully");
      queryClient.invalidateQueries(["collaboration", type]);
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await updateCollaboration(values._id, values),
    onSettled: () => {
      queryClient.invalidateQueries(["collaboration", type]);
    },
  });

  return (
    <Access granted={user?.viewCollaborations}>
      <Table
        dataSource={data}
        loading={isLoading}
        columns={[
          {
            title: "Action",
            render: (record) => (
              <Space size="middle" direction="vertical">
                <>
                  {user?.approveCollaboration && type !== "Approved" && (
                    <Button
                      type="primary"
                      loading={isPending}
                      onClick={() => {
                        Modal.confirm({
                          title: "Are you sure?",
                          content: "Do you want to approve this collaboration?",
                          onOk: () =>
                            mutate({
                              _id: record._id,
                              isApproved: true,
                              collaborationStatus: "Approved",
                            }),
                        });
                      }}
                    >
                      Approve
                    </Button>
                  )}
                  {user?.rejectCollaboration && type !== "Rejected" && (
                    <Button
                      type="dashed"
                      danger
                      onClick={() => {
                        Modal.confirm({
                          title: "Are you sure?",
                          content: "Do you want to reject this collaboration?",
                          onOk: () => {
                            mutate({
                              _id: record._id,
                              isApproved: false,
                              collaborationStatus: "Rejected",
                            });
                          },
                        });
                      }}
                    >
                      Reject
                    </Button>
                  )}
                </>

                {user?.deleteCollaboration && (
                  <Popconfirm
                    title="Delete"
                    description="Are you sure to delete this ?"
                    onConfirm={() => {
                      deletePost(record._id);
                    }}
                    onCancel={() => {
                      console.log("canceled");
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{
                        width: "100%",
                      }}
                      danger
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                )}
              </Space>
            ),
          },
          {
            title: "Image",
            dataIndex: ["user", "image"],
            render: (image) => (
              <Image src={image} alt="user" width={100} height={100} />
            ),
          },
          {
            title: "Name",
            dataIndex: ["user", "name"],
          },
          {
            title: "Phone Number",
            dataIndex: ["user", "phoneNumber"],
          },
          {
            title: "Upload Date",
            dataIndex: ["uploadDate"],
            render: (date) => new Date(date).toLocaleDateString(),
          },
          {
            title: "Player",
            dataIndex: ["link"],
            render: (link, record) =>
              record?.linkType === "Video" ? (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.info({
                      title: "Player",
                      width: 800,
                      content: (
                        <video
                          controls
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <source src={link} type="video/mp4" />
                        </video>
                      ),
                    });
                  }}
                >
                  Watch Video
                </Button>
              ) : (
                <Image src={link} alt="player" width={100} height={100} />
              ),
          },
        ]}
      />
    </Access>
  );
};
