import axios from "axios";

export const URL = "https://api.filmykart.co.in";

export const dashboard = async () => {
  const response = await axios.get(`${URL}/admin/dashboard`);
  return response.data;
};

export const login = async (data) => {
  const { data: res } = await axios.post(`${URL}/admin/login`, data);
  return res;
};

export const getAllCategories = async (page = 1, limit = 50, search = "") => {
  const params = new URLSearchParams();
  params.append("page", page);
  params.append("limit", limit);
  if (search) params.append("search", search);

  const { data } = await axios.get(
    `${URL}/admin/categories?${params.toString()}`
  );
  return data;
};

export const getAllCategoriesForDownload = async () => {
  const { data } = await axios.get(`${URL}/admin/categories?limit=10000`);
  return data;
};

export const deleteCategories = async (id) => {
  const { data } = await axios.delete(`${URL}/admin/category/${id}`);
  return data;
};

export const addCategory = async (data) => {
  const { data: res } = await axios.post(`${URL}/admin/category`, data);
  return res;
};

export const getCategoryById = async (id) => {
  const { data } = await axios.get(`${URL}/admin/category/${id}`);
  return data;
};

export const getUserById = async (id) => {
  const { data } = await axios.get(`${URL}/admin/user/${id}`);
  return data;
};

export const updateCategory = async (id, data) => {
  const { data: res } = await axios.put(`${URL}/admin/category/${id}`, data);
  return res;
};

export const updateUser = async (id, data) => {
  const { data: res } = await axios.put(`${URL}/admin/user/${id}`, data);
  return res;
};

export const getAllSubCategoriesByCategory = async (id) => {
  const { data } = await axios.get(`${URL}/admin/subcategories/${id}`);
  return data;
};

export const deleteSubCategories = async (id) => {
  const { data } = await axios.delete(`${URL}/admin/subcategory/${id}`);
  return data;
};

export const addSubCategory = async (data) => {
  const { data: res } = await axios.post(`${URL}/admin/subcategory`, data);
  return res;
};

export const getTransactions = async () => {
  const { data } = await axios.get(`${URL}/admin/transactions`);
  return data;
};

export const getSubCategoryById = async (id) => {
  const { data } = await axios.get(`${URL}/admin/subcategory/${id}`);
  return data;
};

export const updateSubCategory = async (id, data) => {
  const { data: res } = await axios.put(`${URL}/admin/subcategory/${id}`, data);
  return res;
};

export const getPendingKYC = async () => {
  const { data } = await axios.get(`${URL}/admin/pending-kyc`);
  return data;
};

export const getUsers = async (
  role,
  userName,
  email,
  phoneNumber,
  state,
  city,
  minAge,
  maxAge,
  categories
) => {
  const requestData = {};

  if (role && role !== "All") requestData.role = role ?? "Talent";
  if (userName) requestData.userName = userName;
  if (email) requestData.email = email;
  if (phoneNumber) requestData.phoneNumber = phoneNumber;
  if (state) requestData.state = state;
  if (city) requestData.city = city;
  if (minAge) requestData.minAge = minAge;
  if (maxAge) requestData.maxAge = maxAge;
  if (categories && categories.length > 0) requestData.categories = categories;

  const { data } = await axios.post(`${URL}/admin/users`, requestData);

  return data;
};

export const addUser = async (data) => {
  const { data: res } = await axios.post(`${URL}/admin/user`, data);
  return res;
};

export const getAllsub = async () => {
  const { data } = await axios.get(`${URL}/admin/subscriptions`);
  return data;
};

export const addSubs = async (data) => {
  const { data: res } = await axios.post(`${URL}/admin/subscription`, data);
  return res;
};

export const deleteSubs = async (id) => {
  const { data } = await axios.delete(`${URL}/admin/subscription/${id}`);
  return data;
};

export const updateSubs = async (id, data) => {
  const { data: res } = await axios.put(
    `${URL}/admin/subscription/${id}`,
    data
  );
  return res;
};

export const getSubById = async (id) => {
  const { data } = await axios.get(`${URL}/admin/subscription/${id}`);
  return data;
};

export const addNotification = async (data) => {
  const { data: res } = await axios.post(`${URL}/admin/notification`, data);
  return res;
};

export const deleteUser = async (id) => {
  const { data } = await axios.delete(`${URL}/admin/user/${id}`);
  return data;
};

export const updateImage = async (id, data) => {
  const { data: res } = await axios.put(`${URL}/admin/user-image/${id}`, data);
  return res;
};

export const addSubscription = async (data) => {
  const { data: res } = await axios.post(`${URL}/admin/subscription`, data);
  return res;
};

export const addUserToSubscription = async (data) => {
  const { data: res } = await axios.put(`${URL}/admin/user-subscription`, data);
  return res;
};

export const getUserSubscriptions = async (id) => {
  const { data } = await axios.get(
    `${URL}/admin/user-subscription-history/${id}`
  );
  return data;
};

export const getUserWallet = async (id) => {
  const { data } = await axios.get(`${URL}/admin/user-wallet/${id}`);
  return data;
};

export const getAllAdvert = async () => {
  const { data } = await axios.get(`${URL}/admin/ads`);
  return data;
};

export const deleteAdvert = async (id) => {
  const { data } = await axios.delete(`${URL}/admin/ad/${id}`);
  return data;
};

export const addAdvert = async (data) => {
  const { data: res } = await axios.post(`${URL}/admin/ad`, data);
  return res;
};

export const getAdvertById = async (id) => {
  const { data } = await axios.get(`${URL}/admin/ad/${id}`);
  return data;
};

export const updateAdvert = async (id, data) => {
  const { data: res } = await axios.put(`${URL}/admin/ad/${id}`, data);
  return res;
};

export const updateSystemValues = async (data) => {
  const { data: res } = await axios.put(`${URL}/admin/system-values`, data);
  return res;
};

export const pendingCollaboration = async () => {
  const { data } = await axios.get(`${URL}/admin/pending-collaborations`);
  return data;
};

export const approvedCollaboration = async () => {
  const { data } = await axios.get(`${URL}/admin/approved-collaborations`);
  return data;
};

export const rejectedCollaboration = async () => {
  const { data } = await axios.get(`${URL}/admin/rejected-collaborations`);
  return data;
};

export const updateCollaboration = async (id, data) => {
  const { data: res } = await axios.put(
    `${URL}/admin/collaboration/${id}`,
    data
  );
  return res;
};

export const deleteCollaboration = async (id) => {
  const { data } = await axios.delete(`${URL}/admin/collaboration/${id}`);
  return data;
};

export const allPosts = async (page) => {
  const { data } = await axios.get(`${URL}/admin/posts?page=${page}&limit=20`);
  return data;
};

export const userPosts = async (id) => {
  const { data } = await axios.get(`${URL}/admin/user-posts/${id}`);
  return data;
};

export const getAllPosts = async (id) => {
  const { data } = await axios.get(`${URL}/admin/user-posts/${id}`);
  return data;
};

export const getAllUsersCollaboration = async (id) => {
  const { data } = await axios.get(`${URL}/admin/user-collaboration/${id}`);
  return data;
};

export const addToWallet = async (id, data) => {
  const { data: res } = await axios.put(`${URL}/admin/wallet/${id}`, data);
  return res;
};

export const addAdmin = async (data2) => {
  const { data } = await axios.post(`${URL}/admin/signup`, data2);
  return data;
};
export const getAllAdmins = async () => {
  const { data: res } = await axios.get(`${URL}/admin/all-admins`);
  return res;
};
export const deleteAdmin = async (id) => {
  const { data: res } = await axios.delete(`${URL}/admin/delete-admin/${id}`);
  return res;
};

export const updateAdmin = async (data, id) => {
  const response = await axios.put(`${URL}/admin/admin-update/${id}`, data);
  return response.data;
};

export const getLeaderboard = async (id) => {
  const { data } = await axios.get(`${URL}/admin/leaderboard/${id}`);
  return data;
};
export const getCollaboration = async (id) => {
  const { data } = await axios.get(`${URL}/admin/ad-collaborations/${id}`);
  return data;
};

export const transferToWallet = async (id) => {
  const { data } = await axios.get(`${URL}/admin/leaderboard-complete/${id}`);
  return data;
};

export const getPurchaseLeader = async (id) => {
  const { data } = await axios.get(`${URL}/admin/user-purchase-records/${id}`);
  return data;
};

export const getUserNotifications = async (id) => {
  const { data } = await axios.get(`${URL}/admin/user-notifications/${id}`);
  return data;
};

export const getSubscriptionHistory = async (userId) => {
  const response = await axios.get(
    `${URL}/admin/user-subscription-history/${userId}`
  );
  return response.data;
};

export const updatePostStatus = async (id, data) => {
  const { data: res } = await axios.put(`${URL}/admin/post/${id}`, data);
  return res;
};

export const deletePost = async (id) => {
  const { data } = await axios.delete(`${URL}/admin/post/${id}`);
  return data;
};

export const addUserSubscription = async (data) => {
  const response = await axios.post(`${URL}/admin/user-subscription`, data);
  return response.data;
};

export const getUserSubscriptionStatus = async (userId) => {
  const response = await axios.get(
    `${URL}/admin/user-subscription-status/${userId}`
  );
  return response.data;
};
export const getPendingJobs = async (page = 1, limit = 30) => {
  const { data } = await axios.get(
    `${URL}/admin/pending-jobs?page=${page}&limit=${limit}`
  );
  return data;
};

export const getAcceptedJobs = async (page = 1, limit = 30) => {
  const { data } = await axios.get(
    `${URL}/admin/accepted-jobs?page=${page}&limit=${limit}`
  );
  return data;
};

export const getRejectedJobs = async (page = 1, limit = 30) => {
  const { data } = await axios.get(
    `${URL}/admin/rejected-jobs?page=${page}&limit=${limit}`
  );
  return data;
};

export const updateJobStatus = async (id, data) => {
  const { data: res } = await axios.put(`${URL}/admin/job/${id}`, data);
  return res;
};

export const deleteJob = async (id) => {
  const { data } = await axios.delete(`${URL}/admin/job/${id}`);
  return data;
};

export const getJobApplications = async (jobId) => {
  const { data } = await axios.get(`${URL}/admin/job-applications/${jobId}`);
  return data;
};

export const getJobApplicationAnalytics = async (id) => {
  const { data } = await axios.get(
    `${URL}/admin/job-application-analytics/${id}`
  );
  return data;
};

export const getPostAnalytics = async (id) => {
  const { data } = await axios.get(`${URL}/admin/post-analytics/${id}`);
  return data;
};

export const getStates = async () => {
  const { data } = await axios.get(`${URL}/states`);
  return data;
};

export const getCitiesByState = async (stateId) => {
  const { data } = await axios.get(`${URL}/city/${stateId}`);
  return data;
};

export const getCitiesByStateName = async (stateName) => {
  const { data } = await axios.get(`${URL}/city/name/${stateName}`);
  return data;
};
