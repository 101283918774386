"use client";
import clsx from "clsx";
import {
  Ampersand,
  ArrowRightLeft,
  Briefcase,
  Building,
  CalendarCheck,
  ChevronDown,
  FileText,
  HardDrive,
  LayoutDashboard,
  Megaphone,
  Network,
  ShieldCheck,
  ShieldHalf,
  Store,
  User2Icon,
} from "lucide-react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../utils/store";

const Sidebar = () => {
  const user = useAuth((state) => state.user);

  const filteredSide = [
    {
      title: "Basic",
      links: [
        {
          title: "Dashboard",
          link: "/",
          icon: LayoutDashboard,
          requiresPermission: "dashboard",
        },
        {
          title: "System Values",
          link: "/system-values",
          icon: HardDrive,
          requiresPermission: "systemValues",
        },
        {
          title: "Admin",
          link: "/admins",
          icon: ShieldCheck,
          requiresPermission: "viewAdmin",
        },
        {
          title: "Subscription",
          link: "/subscription",
          icon: CalendarCheck,
          requiresPermission: "dashboard",
        },
        {
          title: "Active Subscription",
          link: "/active-subscription",
          icon: CalendarCheck,
          requiresPermission: "activeSubscription",
        },
      ].filter((item) => user?.[item.requiresPermission]),
    },
    {
      title: "Category",
      links: [
        {
          title: "Category",
          link: "/categories",
          icon: Network,
          requiresPermission: "viewCategory",
        },
      ],
      requiresPermission: "viewCategory",
    },
    {
      title: "Users",
      links: [
        {
          title: "Talent",
          link: "/users",
          icon: User2Icon,
          requiresPermission: "viewTalent",
        },
        {
          title: "Talent Seekers",
          link: "/vendors",
          icon: Store,
          requiresPermission: "viewTalentSeeker",
        },
        {
          title: "Company",
          link: "/company",
          icon: Building,
          requiresPermission: "viewCompany",
        },
      ].filter((item) => user?.[item.requiresPermission]),
    },
    {
      title: "KYC",
      links: [
        {
          title: "KYC",
          link: "/kyc",
          icon: ShieldHalf,
          requiresPermission: "kyc",
        },
        {
          title: "Verify KYC",
          link: "/kyc-verified",
          icon: ShieldHalf,
          requiresPermission: "kyc",
        },
      ],
      requiresPermission: "kyc",
    },
    {
      title: "Posts",
      links: [
        {
          title: "Posts",
          link: "/posts",
          icon: FileText,
          requiresPermission: "viewPosts",
        },
      ],
      requiresPermission: "viewPosts",
    },
    {
      title: "Jobs",
      links: [
        {
          title: "Jobs Management",
          link: "/jobs",
          icon: Briefcase,
          requiresPermission: "viewJobs",
        },
      ],
      requiresPermission: "viewJobs",
    },
    {
      title: "Earning",
      links: [
        {
          title: "Transactions",
          link: "/transactions",
          icon: ArrowRightLeft,
          requiresPermission: "viewTransactions",
        },
      ],
      requiresPermission: "viewTransactions",
    },
    {
      title: "Advertisement",
      links: [
        {
          title: "Advertisement",
          link: "/advert",
          icon: Megaphone,
          requiresPermission: "viewAdvertisements",
        },
        {
          title: "Collaboration",
          link: "/collaboration",
          icon: Ampersand,
          requiresPermission: "viewCollaborations",
        },
        {
          title: "Collaboration Approved",
          link: "/collaboration-approved",
          icon: Ampersand,
          requiresPermission: "viewCollaborations",
        },
        {
          title: "Collaboration Rejected",
          link: "/collaboration-rejected",
          icon: Ampersand,
          requiresPermission: "viewCollaborations",
        },
      ].filter((item) => user?.[item.requiresPermission]),
    },
  ].filter((section) => {
    if (section.requiresPermission) {
      return user?.[section.requiresPermission];
    }
    return section.links && section.links.length > 0;
  });

  return (
    <aside className="w-[18rem] p-6 sticky top-0 flex flex-col gap-2  bg-white shadow-sm  border-r">
      {filteredSide.map((s, index) => (
        <Nav key={index} link={s} index={0} />
      ))}
    </aside>
  );
};

export default Sidebar;

const Nav = ({ link, index }) => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const user = useAuth((state) => state.user);

  if (link?.requiresPermission && !user?.[link.requiresPermission]) {
    return null;
  }

  if (link?.links) {
    const visibleLinks = link.links.filter(
      (l) => !l.requiresPermission || user?.[l.requiresPermission]
    );
    if (visibleLinks.length === 0) {
      return null;
    }
  }

  return (
    <div className="flex flex-col gap-3">
      {index === 0 ? (
        <div>
          <p className="font-bold my-3 uppercase text-xs  text-gray-500 ">
            {link?.title}
          </p>
          {link?.links?.map((l, i) => (
            <Nav key={i} link={l} index={index + 1} />
          ))}
        </div>
      ) : link?.links ? (
        <div key={link.title}>
          <button
            onClick={() => setOpen(!open)}
            className="flex gap-4 rounded-lg text-gray-600  items-center justify-between px-4 py-2 w-full hover:bg-gray-100 transition-colors "
          >
            <div className="flex gap-2 font-semibold text-sm  items-center">
              <link.icon size={16} />
              {link.title}
            </div>
            <ChevronDown size={16} />
          </button>
          {open && (
            <div>
              {link?.links?.map((l, i) => (
                <Nav key={i} link={l} index={index + 1} />
              ))}
            </div>
          )}
        </div>
      ) : (
        <Link
          to={link.link}
          key={link.title}
          className={clsx(
            "flex gap-2 font-semibold text-sm px-4 hover:text-black rounded-lg py-2 text-gray-600 items-center hover:bg-gray-100 transition-colors ",
            location.pathname === link.link && "bg-primary text-white"
          )}
        >
          {link.icon && <link.icon size={16} />}
          {link.title}
        </Link>
      )}
    </div>
  );
};
