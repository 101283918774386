import { UploadOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Image,
  Popconfirm,
  Space,
  Table,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useState } from "react";
import { exportToExcel } from "react-json-to-excel";
import { Link } from "react-router-dom";
import Access from "../../components/Access";
import useSearch from "../../components/common/useSearch2";
import { deleteUser, getUsers, updateImage } from "../../utils/api";
import { useAuth } from "../../utils/store";
const defaultfilters = {
  userName: null,
  email: null,
  phoneNumber: null,
};
const AllUser = ({ vendor, company }) => {
  const user = useAuth((state) => state.user);
  const [search, setSearch] = useState(defaultfilters);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["allUsers", search, vendor, company],
    queryFn: () =>
      getUsers(
        vendor && !company ? "Talent Seeker" : company && "Company",
        search?.userName,
        search?.email,
        search?.phoneNumber
      ),
    enabled: company
      ? !!user?.viewCompany
      : vendor
      ? !!user?.viewTalent
      : !!user?.viewTalentSeeker,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await deleteUser(values),
    onSuccess: () => {
      message.success(" deleted successfully");
      refetch();
    },
  });

  const { mutateAsync: updateUserImage, isPending: loading3 } = useMutation({
    mutationFn: async (values) =>
      await updateImage(values?.id, values?.formData),
    onSuccess: () => {
      message.success("User image updated successfully");
      refetch();
    },
  });
  console.log(search);

  const search2 = useSearch(
    () => {
      setSearch(defaultfilters);
    },
    (data) => {
      console.log(data);

      setSearch((prev) => {
        return { ...prev, ...data };
      });
    }
  );

  const dob = !company
    ? [
        {
          title: "Gender",
          dataIndex: "gender",
        },
        {
          title: "DOB",
          dataIndex: "dob",
        },
        {
          title: "Age",
          dataIndex: "age",
        },
      ]
    : [];

  return (
    <Access
      granted={
        company
          ? !!user?.viewCompany
          : vendor
          ? !!user?.viewTalentSeeker
          : !!user?.viewTalent
      }
    >
      <div>
        <Space
          size={"large"}
          direction="horizontal"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Typography.Title>
            {vendor ? (company ? "Companies" : "Talent Seekers") : "Talent"}
          </Typography.Title>
          {/* <Link to={vendor ? "/vendors/add" : "/users/add"}>
          <Button type="primary">Add </Button>
        </Link> */}
          {(company
            ? !!user?.downloadCompanyData
            : vendor
            ? user?.downloadTalentData
            : user?.downloadTalentSeekerData) && (
            <Space size="middle" direction="horizontal">
              <Button
                type="primary"
                onClick={() => {
                  exportToExcel(
                    data.map((data) => {
                      delete data.__v;
                      return data;
                    }),

                    `talent${new Date().getTime()}`
                  );
                }}
              >
                Download
              </Button>
            </Space>
          )}
        </Space>

        {/* <Space
          size="middle"
          direction="horizontal"
          style={{ marginBottom: 20 }}
        >
          <Input
            placeholder="Search by user name"
            onChange={(e) => setSearch({ ...search, userName: e.target.value })}
          />
          <Input
            placeholder="Search by email"
            onChange={(e) => setSearch({ ...search, email: e.target.value })}
          />
          <Input
            placeholder="Search by phone number"
            onChange={(e) =>
              setSearch({ ...search, phoneNumber: e.target.value })
            }
          />
        </Space> */}

        <Table
          scroll={{ x: 800 }}
          dataSource={
            company
              ? data?.filter((user) => user.role === "Company")
              : vendor
              ? data?.filter((user) => user.role === "Talent Seeker")
              : data?.filter((user) => user.role === "Talent")
          }
          loading={isLoading}
          columns={[
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              render: (text, record) => (
                <Space size="middle" direction="vertical">
                  {((record?.role === "Company" &&
                    user?.viewCompanyPurchases) ||
                    (record?.role === "Talent Seeker" &&
                      user?.viewTalentSeekerPurchases) ||
                    (record?.role === "Talent" &&
                      user?.viewTalentPurchases)) && (
                    <Link to={`/users/purchase/${record._id}`}>
                      <Button style={{ width: "100%" }} type="primary">
                        Purchase
                      </Button>
                    </Link>
                  )}

                  <Link to={`/users/wallet/${record._id}`}>
                    <Button style={{ width: "100%" }} type="primary">
                      Wallet
                    </Button>
                  </Link>
                  <Link to={`/users/notifications/${record._id}`}>
                    <Button style={{ width: "100%" }} type="primary">
                      Notifications
                    </Button>
                  </Link>
                  {/* <Link to={`/users/subscription/${record._id}`}>
                <Button style={{ width: "100%" }} type="primary">
                  Subscription
                </Button>
              </Link> */}

                  <Link
                    to={`/${vendor ? "vendors" : "users"}/update/${
                      record?._id
                    }`}
                  >
                    <Button
                      style={{
                        width: "100%",
                      }}
                      type="primary"
                    >
                      Update
                    </Button>
                  </Link>
                  {user?.viewPosts && (
                    <Link to={`/users/posts/${record?._id}`}>
                      <Button
                        style={{
                          width: "100%",
                        }}
                        type="primary"
                      >
                        Posts
                      </Button>
                    </Link>
                  )}
                  {((record?.role === "Company" &&
                    user?.viewCompanyCollaboration) ||
                    (record?.role === "Talent Seeker" &&
                      user?.viewTalentSeekerCollaboration) ||
                    (record?.role === "Talent" &&
                      user?.viewTalentCollaboration)) && (
                    <Link to={`/users/collaboration/${record?._id}`}>
                      <Button
                        style={{
                          width: "100%",
                        }}
                        type="primary"
                      >
                        Collaboration
                      </Button>
                    </Link>
                  )}
                  {((record?.role === "Company" && user?.updateCompanyPhoto) ||
                    (record?.role === "Talent Seeker" &&
                      user?.updateTalentSeekerPhoto) ||
                    (record?.role === "Talent" && user?.updateTalentPhoto)) && (
                    <Upload
                      customRequest={async ({ file, onSuccess }) => {
                        const formData = new FormData();
                        formData.append("image", file);
                        await updateUserImage({
                          id: record._id,
                          formData,
                        });
                        onSuccess("ok");
                      }}
                      showUploadList={false}
                    >
                      <Button
                        style={{
                          width: "100%",
                        }}
                        icon={<UploadOutlined />}
                        loading={loading3}
                      >
                        Update User Photo
                      </Button>
                    </Upload>
                  )}
                  {((record?.role === "Company" && user?.deleteCompany) ||
                    (record?.role === "Talent Seeker" &&
                      user?.deleteTalentSeeker) ||
                    (record?.role === "Talent" && user?.deleteTalent)) && (
                    <Popconfirm
                      title="Delete"
                      description="Are you sure to delete this ?"
                      onConfirm={() => {
                        mutate(record._id);
                      }}
                      onCancel={() => {
                        console.log("canceled");
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        style={{
                          width: "100%",
                        }}
                        danger
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  )}
                </Space>
              ),
            },
            {
              title: "User Name",
              dataIndex: "userName",
              ...search2("userName"),
            },
            {
              title: "Image",
              dataIndex: "image",
              render: (text, record) => (
                <Image
                  src={text}
                  alt="blog"
                  style={{
                    width: 100,
                    height: 100,
                    objectFit: "contain",
                    backgroundColor: "#f0f0f0",
                  }}
                />
              ),
            },

            {
              title: "Name",
              dataIndex: "name",
            },
            {
              title: "Email",
              dataIndex: "email",
              ...search2("email"),
            },

            {
              title: "Phone Number",
              dataIndex: "phoneNumber",
              ...search2("phoneNumber"),
            },
            ...dob,

            {
              title: "City",
              dataIndex: "city",
            },

            {
              title: "State",
              dataIndex: "state",
            },

            {
              title: "Role",
              dataIndex: "role",
            },
          ]}
        />
      </div>
    </Access>
  );
};

export default AllUser;
