import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Switch,
  Typography,
  Upload,
} from "antd";

import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import React from "react";

const prices = [
  "priceMoney",
  "firstPrice",
  "secondPrice",
  "thirdPrice",
  "fourthPrice",
  "fifthPrice",
  "sixthPrice",
  "seventhPrice",
  "eighthPrice",
  "ninthPrice",
  "tenthPrice",
  "restPrice",
];

const split = ["Money", "Price"];

const Advert = ({ mutate, update, isPending }) => {
  console.log(update);
  const [form] = Form.useForm();
  const check = Form.useWatch("isCollaboration", form);
  React.useEffect(() => {
    if (update) {
      const price = prices.reduce((acc, key) => {
        acc[key] = update[key];
        return acc;
      }, {});

      const priceList = update?.priceList?.map((item) => {
        return {
          rank: Object.keys(item)[0],
          price: Object.values(item)[0],
        };
      });
      form.setFieldsValue({
        name: update.name,
        linkType: update.linkType,
        position: update.position,
        expiredDate: dayjs(update.expiredDate),
        lastSubmissionDate: dayjs(update.lastSubmissionDate),
        isCollaboration: update.isCollaboration,
        priceMoney: update.priceMoney,
        priceList: priceList,
        ...price,
      });
    }
  }, [update, form]);

  return (
    <Form
      form={form}
      style={{
        maxWidth: 600,
        width: "100%",
        margin: "auto",
        marginTop: 50,
        border: "1px solid #ccc",
        padding: "20px",
        borderRadius: 10,
      }}
      initialValues={{
        linkType: "Video",
      }}
      onFinish={(values) => {
        if (values?.isCollaboration && !values?.priceList?.length && !update) {
          return message.error("Please add price list");
        }
        const formData = new FormData();

        formData.append("name", values.name);

        if (values.image) {
          formData.append("image", values.image.file.originFileObj);
        }

        if (values.ad) {
          formData.append("ad", values.ad.file.originFileObj);
        }

        formData.append("linkType", values.linkType);
        formData.append("position", values.position);
        if (values?.expiredDate) {
          const expiredDate = new Date(values.expiredDate);
          const fixedExpiredDate = new Date(
            expiredDate.setHours(23, 59, 59, 999)
          ).toISOString();
          formData.append("expiredDate", fixedExpiredDate);
        }
        if (values?.lastSubmissionDate) {
          const lastSubmissionDate = new Date(values.lastSubmissionDate);
          const fixedLastSubmissionDate = new Date(
            lastSubmissionDate.setHours(23, 59, 59, 999)
          ).toISOString();

          formData.append("lastSubmissionDate", fixedLastSubmissionDate);
        }
        formData.append("isCollaboration", values.isCollaboration ?? false);
        // formData.append("priceMoney", values.priceMoney);

        if (values.priceList) {
          const priceList = values.priceList.map((key) => {
            return {
              [key?.rank]: key?.price,
            };
          });

          formData.append("priceList", JSON.stringify(priceList));
        }

        prices.forEach((key) => {
          if (values[key]) {
            formData.append(key, values[key]);
          }
        });

        mutate(formData);
      }}
      labelCol={{
        span: 8,
      }}
    >
      <Typography.Title level={3}>
        {update ? "Update Advertisement" : "Add Advertisement"}
      </Typography.Title>
      <Form.Item
        label="Name"
        name="name"
        required
        rules={[
          {
            required: true,
            message: "Please select",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Type"
        name="linkType"
        rules={[
          {
            required: true,
            message: "Please select type",
          },
        ]}
      >
        <Select
          disabled
          options={[
            {
              label: "Video",
              value: "Video",
            },
            {
              label: "Image",
              value: "Image",
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Position"
        name="position"
        required
        rules={[
          {
            required: true,
            message: "Please select",
          },
        ]}
      >
        <InputNumber
          style={{
            width: "100%",
          }}
        />
      </Form.Item>
      <Form.Item
        label="Expired Date"
        name="expiredDate"
        required
        rules={[
          {
            required: true,
            message: "Please select",
          },
        ]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        rules={
          !update && [
            {
              required: true,
              message: "Please select",
            },
          ]
        }
        label="Logo"
        name="image"
        style={{
          width: "100%",
        }}
      >
        <Upload>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        rules={
          !update && [
            {
              required: true,
              message: "Please select",
            },
          ]
        }
        label="Video"
        name="ad"
        style={{
          width: "100%",
        }}
      >
        <Upload>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>
      {/* {!update &&
        prices.map((price) => (
          <Form.Item
            label={price
              .split(/(?=[A-Z])/)
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
            name={price}
            key={price}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        ))} */}
      <Form.Item label="Collaboration" name="isCollaboration">
        <Switch
          disabled={update}
          checkedChildren="Yes"
          unCheckedChildren="No"
          checked={check}
        />
      </Form.Item>
      {check && (
        <>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select",
              },
            ]}
            label="Last Submission Date"
            name="lastSubmissionDate"
            required
          >
            <DatePicker />
          </Form.Item>
          {!update && (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please select",
                },
              ]}
              label="Price Money"
              name="priceMoney"
              required
            >
              <InputNumber
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          )}
        </>
      )}
      {!update && check && (
        <>
          <h3
            style={{
              marginBottom: 16,
            }}
          >
            Price List
          </h3>
          <Form.List name="priceList">
            {(fields, { add, remove }) => (
              <div
                style={{ display: "flex", rowGap: 16, flexDirection: "column" }}
              >
                {fields.map((field) => (
                  <Card
                    size="small"
                    title={`Price ${field.name + 1}`}
                    key={field.key}
                    extra={
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    }
                  >
                    <Form.Item
                      label="Rank"
                      name={[field.name, "rank"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Price"
                      name={[field.name, "price"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Card>
                ))}

                <Button
                  style={{
                    marginBottom: 16,
                  }}
                  type="dashed"
                  onClick={() => add()}
                  block
                >
                  + Add Price
                </Button>
              </div>
            )}
          </Form.List>
        </>
      )}

      <Button htmlType="submit" loading={isPending} type="primary">
        Submit
      </Button>
    </Form>
  );
};

export default Advert;
